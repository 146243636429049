// if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
//     document.write('<script src="https://cdnjs.cloudflare.com/ajax/libs/babel-core/5.6.15/browser-polyfill.min.js"><\/script>')
// }

/**
 * Document Ready
 */

document.addEventListener("DOMContentLoaded", () => {
   // polyfills

   if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
          thisArg = thisArg || window;
          for (var i = 0; i < this.length; i++) {
              callback.call(thisArg, this[i], i, this);
          }
      };
   }

   (function(ELEMENT) {
      ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
      ELEMENT.closest = ELEMENT.closest || function closest(selector) {
         if (!this) return null;
         if (this.matches(selector)) return this;
         if (!this.parentElement) {return null}
         else return this.parentElement.closest(selector)
      };
   }(Element.prototype));

   /*
    * classList.js: Cross-browser full element.classList implementation.
    * 1.2.20171210
    *
    * By Eli Grey, http://eligrey.com
    * License: Dedicated to the public domain.
    *   See https://github.com/eligrey/classList.js/blob/master/LICENSE.md
    */

   /*global self, document, DOMException */

   /*! @source http://purl.eligrey.com/github/classList.js/blob/master/classList.js */

   if ("document" in self) {

      // Full polyfill for browsers with no classList support
      // Including IE < Edge missing SVGElement.classList
      if (
            !("classList" in document.createElement("_"))
         || document.createElementNS
         && !("classList" in document.createElementNS("http://www.w3.org/2000/svg","g"))
      ) {

      (function (view) {

      "use strict";

      if (!('Element' in view)) return;

      var
           classListProp = "classList"
         , protoProp = "prototype"
         , elemCtrProto = view.Element[protoProp]
         , objCtr = Object
         , strTrim = String[protoProp].trim || function () {
            return this.replace(/^\s+|\s+$/g, "");
         }
         , arrIndexOf = Array[protoProp].indexOf || function (item) {
            var
                 i = 0
               , len = this.length
            ;
            for (; i < len; i++) {
               if (i in this && this[i] === item) {
                  return i;
               }
            }
            return -1;
         }
         // Vendors: please allow content code to instantiate DOMExceptions
         , DOMEx = function (type, message) {
            this.name = type;
            this.code = DOMException[type];
            this.message = message;
         }
         , checkTokenAndGetIndex = function (classList, token) {
            if (token === "") {
               throw new DOMEx(
                    "SYNTAX_ERR"
                  , "The token must not be empty."
               );
            }
            if (/\s/.test(token)) {
               throw new DOMEx(
                    "INVALID_CHARACTER_ERR"
                  , "The token must not contain space characters."
               );
            }
            return arrIndexOf.call(classList, token);
         }
         , ClassList = function (elem) {
            var
                 trimmedClasses = strTrim.call(elem.getAttribute("class") || "")
               , classes = trimmedClasses ? trimmedClasses.split(/\s+/) : []
               , i = 0
               , len = classes.length
            ;
            for (; i < len; i++) {
               this.push(classes[i]);
            }
            this._updateClassName = function () {
               elem.setAttribute("class", this.toString());
            };
         }
         , classListProto = ClassList[protoProp] = []
         , classListGetter = function () {
            return new ClassList(this);
         }
      ;
      // Most DOMException implementations don't allow calling DOMException's toString()
      // on non-DOMExceptions. Error's toString() is sufficient here.
      DOMEx[protoProp] = Error[protoProp];
      classListProto.item = function (i) {
         return this[i] || null;
      };
      classListProto.contains = function (token) {
         return ~checkTokenAndGetIndex(this, token + "");
      };
      classListProto.add = function () {
         var
              tokens = arguments
            , i = 0
            , l = tokens.length
            , token
            , updated = false
         ;
         do {
            token = tokens[i] + "";
            if (!~checkTokenAndGetIndex(this, token)) {
               this.push(token);
               updated = true;
            }
         }
         while (++i < l);

         if (updated) {
            this._updateClassName();
         }
      };
      classListProto.remove = function () {
         var
              tokens = arguments
            , i = 0
            , l = tokens.length
            , token
            , updated = false
            , index
         ;
         do {
            token = tokens[i] + "";
            index = checkTokenAndGetIndex(this, token);
            while (~index) {
               this.splice(index, 1);
               updated = true;
               index = checkTokenAndGetIndex(this, token);
            }
         }
         while (++i < l);

         if (updated) {
            this._updateClassName();
         }
      };
      classListProto.toggle = function (token, force) {
         var
              result = this.contains(token)
            , method = result ?
               force !== true && "remove"
            :
               force !== false && "add"
         ;

         if (method) {
            this[method](token);
         }

         if (force === true || force === false) {
            return force;
         } else {
            return !result;
         }
      };
      classListProto.replace = function (token, replacement_token) {
         var index = checkTokenAndGetIndex(token + "");
         if (~index) {
            this.splice(index, 1, replacement_token);
            this._updateClassName();
         }
      }
      classListProto.toString = function () {
         return this.join(" ");
      };

      if (objCtr.defineProperty) {
         var classListPropDesc = {
              get: classListGetter
            , enumerable: true
            , configurable: true
         };
         try {
            objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
         } catch (ex) { // IE 8 doesn't support enumerable:true
            // adding undefined to fight this issue https://github.com/eligrey/classList.js/issues/36
            // modernie IE8-MSW7 machine has IE8 8.0.6001.18702 and is affected
            if (ex.number === undefined || ex.number === -0x7FF5EC54) {
               classListPropDesc.enumerable = false;
               objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
            }
         }
      } else if (objCtr[protoProp].__defineGetter__) {
         elemCtrProto.__defineGetter__(classListProp, classListGetter);
      }

      }(self));

      }

      // There is full or partial native classList support, so just check if we need
      // to normalize the add/remove and toggle APIs.

      (function () {
         "use strict";

         var testElement = document.createElement("_");

         testElement.classList.add("c1", "c2");

         // Polyfill for IE 10/11 and Firefox <26, where classList.add and
         // classList.remove exist but support only one argument at a time.
         if (!testElement.classList.contains("c2")) {
            var createMethod = function(method) {
               var original = DOMTokenList.prototype[method];

               DOMTokenList.prototype[method] = function(token) {
                  var i, len = arguments.length;

                  for (i = 0; i < len; i++) {
                     token = arguments[i];
                     original.call(this, token);
                  }
               };
            };
            createMethod('add');
            createMethod('remove');
         }

         testElement.classList.toggle("c3", false);

         // Polyfill for IE 10 and Firefox <24, where classList.toggle does not
         // support the second argument.
         if (testElement.classList.contains("c3")) {
            var _toggle = DOMTokenList.prototype.toggle;

            DOMTokenList.prototype.toggle = function(token, force) {
               if (1 in arguments && !this.contains(token) === !force) {
                  return force;
               } else {
                  return _toggle.call(this, token);
               }
            };

         }

         // replace() polyfill
         if (!("replace" in document.createElement("_").classList)) {
            DOMTokenList.prototype.replace = function (token, replacement_token) {
               var
                    tokens = this.toString().split(" ")
                  , index = tokens.indexOf(token + "")
               ;
               if (~index) {
                  tokens = tokens.slice(index);
                  this.remove.apply(this, tokens);
                  this.add(replacement_token);
                  this.add.apply(this, tokens.slice(1));
               }
            }
         }

         testElement = null;
      }());

      }


   const lockBody = () => {
      const $body = document.body
      $body.classList.contains('_lock') ?
      $body.classList.remove('_lock') :
      $body.classList.add('_lock')
   }

   // polyfilled smooth scrolling for IE, Edge & Safari
   const smoothScrollTo = (to, duration) => {
      const element = document.scrollingElement || document.documentElement,
          start = element.scrollTop,
          change = to - start,
          startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t, b, c, d) => {
          t /= d/2;
          if (t < 1) return c/2*t*t + b;
          t--;
          return -c/2 * (t*(t-2) - 1) + b;
      };

      const animateScroll = _ => {
          const currentDate = +new Date();
          const currentTime = currentDate - startDate;
          element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
          if(currentTime < duration) {
              requestAnimationFrame(animateScroll);
          }
          else {
              element.scrollTop = to;
          }
      };
      animateScroll();
   };

   // detect support for the behavior property in ScrollOptions
   const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;


   // IE11 detection

   const detectIE = () => {
      return !!window.MSInputMethodContext && !!document.documentMode
   }

   if (detectIE()) {
      document.querySelector('html').classList.add('_browser-ie')
   }

   // scroll to handlers

   const scrollToElement = ($el) => {
      const topPos = $el.offsetTop
      const $burgerMenu = document.querySelector('.js-popup[data-popup=burger]')
      if ($burgerMenu.classList.contains('_active')) {
         $burgerMenu.classList.remove('_active')
         lockBody()
      }
      if (supportsNativeSmoothScroll) {
         window.scrollTo({ top: topPos, left: 0, behavior: 'smooth' })
     } else {
         smoothScrollTo(topPos, 600);
     }

   }

   const scrollToSettings = (e) => {
      const $settings = document.querySelector('.js-settings')
      if(!!$settings) {
         if (!!e) e.preventDefault()
         scrollToElement($settings)
      }
   }

   const scrollToFaq = (e) => {
      e.preventDefault()
      const $faq = document.querySelector('.js-faq')
      scrollToElement($faq)
   }

   const $scrollToSettings = document.querySelectorAll('.js-scroll-to-settings')

   $scrollToSettings.forEach($el => {
      $el.addEventListener('click', scrollToSettings)
   })

   const $scrollToFaq = document.querySelectorAll('.js-scroll-to-faq')

   $scrollToFaq.forEach($el => {
      $el.addEventListener('click', scrollToFaq)
   })

   window.addEventListener('load', () => {
      if (location.hash == '#settings') {
         window.scrollTo(0, 0);
         setTimeout(() => {
            scrollToSettings()
         }, 300)
      }
   })

   // popup functions

   const blurBg = () => {
      const $wrapper = document.querySelector('.js-wrapper')
      if (!!$wrapper) {
         $wrapper.classList.contains('_blur') ?
         $wrapper.classList.remove('_blur') :
         $wrapper.classList.add('_blur')
      }
   }

   const togglePopup = (id, blur) => {
      const $popup = document.querySelector(`.js-popup[data-popup=${id}]`)
      $popup.classList.contains('_active') ?
      $popup.classList.remove('_active') :
      $popup.classList.add('_active')
      lockBody()
      if (blur) blurBg()
   }

   const $closePopup = document.querySelectorAll('.js-close-popup')

   $closePopup.forEach($el => {
      $el.addEventListener('click', () => {
         togglePopup($el.getAttribute('data-popup'))

         const $submenuElements = document.querySelectorAll(`.js-submenu`)
         Array.prototype.forEach.call($submenuElements, ($element) => $element.classList.remove('_active'))
      })
   })


   //xhr send

   function sendData(url,method, data = '', callback, onError) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.send(data);

      xhr.onload = function() {
         callback(xhr.response);
      };

      xhr.onerror = function () {
         if (onError !== undefined) {
            onError()
         }
      }
   }

   function setVh () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   setVh();

   
   const $header = document.querySelector('.js-header')

   function headerChange() {
      if (window.scrollY > 10 || window.pageYOffset > 10) {
         $header.classList.add('_header-fixed')
      } else {
         $header.classList.remove('_header-fixed')
      }
   }

   headerChange();

   window.addEventListener('scroll', () => {
      headerChange()
   })

   // burger

   const $burger = document.querySelector('.js-burger')
   const $openSubmenuClass = 'js-open-submenu';
   const $closeSubmenuClass = 'js-close-submenu';


   $burger.addEventListener('click', () => {
      togglePopup($burger.getAttribute('data-popup'))
   })

   const toggleSubmenu = (id) => {
      const $submenu = document.querySelector(`.js-submenu[data-submenu=${id}]`)
      $submenu.classList.contains('_active') ?
      $submenu.classList.remove('_active') :
      $submenu.classList.add('_active')
   }

   document.addEventListener('click', (event) => {
       const $el = event.target;

      if($el.classList.contains($openSubmenuClass)) {
         toggleSubmenu($el.getAttribute('data-submenu'));
      } else if($el.classList.contains($closeSubmenuClass)) {
         toggleSubmenu($el.closest('.js-submenu').getAttribute('data-submenu'))
      }

      return;
   });
   const $toggleShare = document.querySelectorAll('.js-toggle-share'),
         $shareMenu = document.querySelector('.js-share-menu'),
         $shareLink = document.querySelector('.index-top__share-link');

   $toggleShare.forEach(el => {
      el.addEventListener('click', () => {
         $shareMenu.classList.toggle('_active')
         $shareLink.classList.toggle('_hidden')
      })
   })

   const $circleShape = document.querySelectorAll('.js-shape')

   setTimeout(() => {
      $circleShape.forEach($el => {
         $el.classList.add('_animated')
      })
   }, 0)


   // canvas animation

   var canvas = document.getElementById("canvas");

   if (!!canvas) {
      var ctx = canvas.getContext("2d");

      var delta = 500;
      var deltaShadow = 1500;

      var data = [
          {mx: 1015, my: 80 + delta, lx: 751, ly: 178 + delta, curveParams: {cp1x: 720, cp1y: 189 + delta, cp2x: 699, cp2y: 217 + delta, x: 696, y:249 + delta}},
          {lx: 659, ly: 704 + delta, curveParams: {cp1x: 656, cp1y: 741 + delta, cp2x: 678, cp2y: 776 + delta, x: 714, y:789 + delta}},
          {lx: 939, ly: 872 + delta, curveParams: {cp1x: 956, cp1y: 879 + delta, cp2x: 975, cp2y: 879 + delta, x: 992, y:874 + delta}},
          {lx: 1245, ly: 798 + delta, curveParams: {cp1x: 1271, cp1y: 790 + delta, cp2x: 1292, cp2y: 770 + delta, x: 1300, y:745 + delta}},
          {lx: 1403, ly: 439 + delta, curveParams: {cp1x: 1413, cp1y: 407 + delta, cp2x: 1404, cp2y: 373 + delta, x: 1380, y:350 + delta}},
          {lx: 1100, ly: 96 + delta, curveParams: {cp1x: 1077, cp1y: 75 + delta, cp2x: 1044, cp2y: 69 + delta, x: 1015, y:80 + delta}},
      ]

      var blurData = [
          {mx: 1015, my: 80 - deltaShadow, lx: 751, ly: 178 - deltaShadow, curveParams: {cp1x: 720, cp1y: 189 - deltaShadow, cp2x: 699, cp2y: 217 - deltaShadow, x: 696, y:249 - deltaShadow}},
          {lx: 659, ly: 704 - deltaShadow, curveParams: {cp1x: 656, cp1y: 741 - deltaShadow, cp2x: 678, cp2y: 776 - deltaShadow, x: 714, y:789 - deltaShadow}},
          {lx: 939, ly: 872 - deltaShadow, curveParams: {cp1x: 956, cp1y: 879 - deltaShadow, cp2x: 975, cp2y: 879 - deltaShadow, x: 992, y:874 - deltaShadow}},
          {lx: 1245, ly: 798 - deltaShadow, curveParams: {cp1x: 1271, cp1y: 790 - deltaShadow, cp2x: 1292, cp2y: 770 - deltaShadow, x: 1300, y:745 - deltaShadow}},
          {lx: 1403, ly: 439 - deltaShadow, curveParams: {cp1x: 1413, cp1y: 407 - deltaShadow, cp2x: 1404, cp2y: 373 - deltaShadow, x: 1380, y:350 - deltaShadow}},
          {lx: 1100, ly: 96 - deltaShadow, curveParams: {cp1x: 1077, cp1y: 75 - deltaShadow, cp2x: 1044, cp2y: 69 - deltaShadow, x: 1015, y:80 - deltaShadow}},
      ]

      var currentAngle    = 0,
          rs1             = 450,
          rs2             = 450,
          a               = 0,
          tx              = 0,
          ty              = 0;

      var tick = 0.5;

      function Animate () {

          ctx.clearRect(0, 0, canvas.width, canvas.height)

          var vx1 = Math.cos(currentAngle) * rs1 / 2;
          var vy1 = Math.sin(currentAngle) * rs1 / 2;

          var vx2 = Math.cos(currentAngle) * rs2 / 2;
          var vy2 = Math.sin(currentAngle) * rs2 / 2;

          a = a * (Math.PI / 180)


          // круг 1
          ctx.save();
          ctx.beginPath();
          ctx.translate(1200, 560 - deltaShadow);
          a += 3;
          ctx.rotate(a)
          ctx.arc(0 + vx1, 0 + vy1, rs1, 0, 2 * Math.PI);
          ctx.shadowColor = "rgb(116, 250, 61)";
          ctx.shadowBlur = 150;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = deltaShadow + 500;
          ctx.fill();
          ctx.restore();

          // круг 2
          ctx.save();
          ctx.beginPath();
          ctx.translate(800, 300 - deltaShadow)
          a += 3
          ctx.rotate(a)
          ctx.arc(0 + vx2, 0 + vy2, rs2, 0, 2 * Math.PI);
          currentAngle += 0.01
          ctx.shadowColor = "rgb(116, 250, 61)";
          ctx.shadowBlur = 150;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = deltaShadow + 500;
          ctx.fill();
          ctx.restore();

          // Фигура
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(data[0].mx, data[0].my);
          ctx.lineTo(data[0].lx, data[0].ly);
          ctx.bezierCurveTo(data[0].curveParams.cp1x, data[0].curveParams.cp1y, data[0].curveParams.cp2x, data[0].curveParams.cp2y, data[0].curveParams.x, data[0].curveParams.y);
          ctx.lineTo(data[1].lx, data[1].ly);
          ctx.bezierCurveTo(data[1].curveParams.cp1x, data[1].curveParams.cp1y, data[1].curveParams.cp2x, data[1].curveParams.cp2y, data[1].curveParams.x, data[1].curveParams.y);
          ctx.lineTo(data[2].lx, data[2].ly);
          ctx.bezierCurveTo(data[2].curveParams.cp1x, data[2].curveParams.cp1y, data[2].curveParams.cp2x, data[2].curveParams.cp2y, data[2].curveParams.x, data[2].curveParams.y);
          ctx.lineTo(data[3].lx, data[3].ly);
          ctx.bezierCurveTo(data[3].curveParams.cp1x, data[3].curveParams.cp1y, data[3].curveParams.cp2x, data[3].curveParams.cp2y, data[3].curveParams.x, data[3].curveParams.y);
          ctx.lineTo(data[4].lx, data[4].ly);
          ctx.bezierCurveTo(data[4].curveParams.cp1x, data[4].curveParams.cp1y, data[4].curveParams.cp2x, data[4].curveParams.cp2y, data[4].curveParams.x, data[4].curveParams.y);
          ctx.lineTo(data[5].lx, data[5].ly);
          ctx.bezierCurveTo(data[5].curveParams.cp1x, data[5].curveParams.cp1y, data[5].curveParams.cp2x, data[5].curveParams.cp2y, data[5].curveParams.x, data[5].curveParams.y);

          if(tick / 200  > 0 && tick / 200 <= 1) {

              ctx.lineTo(data[1].lx-=0.175, data[1].ly+=0.175);
              ctx.bezierCurveTo(data[1].curveParams.cp1x-=0.175, data[1].curveParams.cp1y+=0.175, data[1].curveParams.cp2x-=0.175, data[1].curveParams.cp2y+=0.175, data[1].curveParams.x-=0.175, data[1].curveParams.y+=0.175);

              ctx.lineTo(data[2].lx-=0.175, data[2].ly+=0.175);
              ctx.bezierCurveTo(data[2].curveParams.cp1x-=0.175, data[2].curveParams.cp1y+=0.175, data[2].curveParams.cp2x-=0.175, data[2].curveParams.cp2y+=0.175, data[2].curveParams.x-=0.175, data[2].curveParams.y+=0.175);

          } else if (tick / 200  > 1 && tick / 200 <= 2){

              ctx.lineTo(data[1].lx+=0.175, data[1].ly-=0.175);
              ctx.bezierCurveTo(data[1].curveParams.cp1x+=0.175, data[1].curveParams.cp1y-=0.175, data[1].curveParams.cp2x+=0.175, data[1].curveParams.cp2y-=0.175, data[1].curveParams.x+=0.175, data[1].curveParams.y-=0.175);

              ctx.lineTo(data[3].lx+=0.175, data[3].ly+=0.175);
              ctx.bezierCurveTo(data[3].curveParams.cp1x+=0.175, data[3].curveParams.cp1y+=0.175, data[3].curveParams.cp2x+=0.175, data[3].curveParams.cp2y+=0.175, data[3].curveParams.x+=0.175, data[3].curveParams.y+=0.175);
      
          } else if (tick / 200  > 2 && tick / 200 <= 3){

              ctx.lineTo(data[2].lx+=0.175, data[2].ly-=0.175);
              ctx.bezierCurveTo(data[2].curveParams.cp1x+=0.175, data[2].curveParams.cp1y-=0.175, data[2].curveParams.cp2x+=0.175, data[2].curveParams.cp2y-=0.175, data[2].curveParams.x+=0.175, data[2].curveParams.y-=0.175);

              ctx.lineTo(data[4].lx+=0.175, data[4].ly-=0.175);
              ctx.bezierCurveTo(data[4].curveParams.cp1x+=0.175, data[4].curveParams.cp1y-=0.175, data[4].curveParams.cp2x+=0.175, data[4].curveParams.cp2y-=0.175, data[4].curveParams.x+=0.175, data[4].curveParams.y-=0.175);
      
          } else if (tick / 200  > 3 && tick / 200 <= 4){

              ctx.lineTo(data[3].lx-=0.175, data[3].ly-=0.175);
              ctx.bezierCurveTo(data[3].curveParams.cp1x-=0.175, data[3].curveParams.cp1y-=0.175, data[3].curveParams.cp2x-=0.175, data[3].curveParams.cp2y-=0.175, data[3].curveParams.x-=0.175, data[3].curveParams.y-=0.175);

              ctx.lineTo(data[5].lx+=0.175, data[5].ly-=0.175);
              ctx.bezierCurveTo(data[5].curveParams.cp1x+=0.175, data[5].curveParams.cp1y-=0.175, data[5].curveParams.cp2x+=0.175, data[5].curveParams.cp2y-=0.175, data[5].curveParams.x+=0.175, data[5].curveParams.y-=0.175);

              ctx.moveTo(data[0].mx+=0.175, data[0].my-=0.175);
              ctx.lineTo(data[0].lx+=0.175, data[0].ly-=0.175);
              ctx.bezierCurveTo(data[0].curveParams.cp1x+=0.175, data[0].curveParams.cp1y-=0.175, data[0].curveParams.cp2x+=0.175, data[0].curveParams.cp2y-=0.175, data[0].curveParams.x+=0.175, data[0].curveParams.y-=0.175);

          } else if (tick / 200  > 4 && tick / 200 <= 5){

              ctx.lineTo(data[4].lx-=0.175, data[4].ly+=0.175);
              ctx.bezierCurveTo(data[4].curveParams.cp1x-=0.175, data[4].curveParams.cp1y+=0.175, data[4].curveParams.cp2x-=0.175, data[4].curveParams.cp2y+=0.175, data[4].curveParams.x-=0.175, data[4].curveParams.y+=0.175);

              ctx.lineTo(data[5].lx-=0.175, data[5].ly+=0.175);
              ctx.bezierCurveTo(data[5].curveParams.cp1x-=0.175, data[5].curveParams.cp1y+=0.175, data[5].curveParams.cp2x-=0.175, data[5].curveParams.cp2y+=0.175, data[5].curveParams.x-=0.175, data[5].curveParams.y+=0.175);

              ctx.moveTo(data[0].mx-=0.175, data[0].my+=0.175);
              ctx.lineTo(data[0].lx-=0.175, data[0].ly+=0.175);
              ctx.bezierCurveTo(data[0].curveParams.cp1x-=0.175, data[0].curveParams.cp1y+=0.175, data[0].curveParams.cp2x-=0.175, data[0].curveParams.cp2y+=0.175, data[0].curveParams.x-=0.175, data[0].curveParams.y+=0.175);
          }
    
          ctx.closePath();
          ctx.fillStyle = "rgb(34, 208, 173)";
          ctx.fill();
          ctx.restore();



          // Фигура blur
          ctx.save();
          ctx.beginPath();


          ctx.moveTo(blurData[0].mx, blurData[0].my);
          ctx.lineTo(blurData[0].lx, blurData[0].ly);
          ctx.bezierCurveTo(blurData[0].curveParams.cp1x, blurData[0].curveParams.cp1y, blurData[0].curveParams.cp2x, blurData[0].curveParams.cp2y, blurData[0].curveParams.x, blurData[0].curveParams.y);

          ctx.lineTo(blurData[1].lx, blurData[1].ly);
          ctx.bezierCurveTo(blurData[1].curveParams.cp1x, blurData[1].curveParams.cp1y, blurData[1].curveParams.cp2x, blurData[1].curveParams.cp2y, blurData[1].curveParams.x, blurData[1].curveParams.y);

          ctx.lineTo(blurData[2].lx, blurData[2].ly);
          ctx.bezierCurveTo(blurData[2].curveParams.cp1x, blurData[2].curveParams.cp1y, blurData[2].curveParams.cp2x, blurData[2].curveParams.cp2y, blurData[2].curveParams.x, blurData[2].curveParams.y);

          ctx.lineTo(blurData[3].lx, blurData[3].ly);
          ctx.bezierCurveTo(blurData[3].curveParams.cp1x, blurData[3].curveParams.cp1y, blurData[3].curveParams.cp2x, blurData[3].curveParams.cp2y, blurData[3].curveParams.x, blurData[3].curveParams.y);

          ctx.lineTo(blurData[4].lx, blurData[4].ly);
          ctx.bezierCurveTo(blurData[4].curveParams.cp1x, blurData[4].curveParams.cp1y, blurData[4].curveParams.cp2x, blurData[4].curveParams.cp2y, blurData[4].curveParams.x, blurData[4].curveParams.y);

          ctx.lineTo(blurData[5].lx, blurData[5].ly);
          ctx.bezierCurveTo(blurData[5].curveParams.cp1x, blurData[5].curveParams.cp1y, blurData[5].curveParams.cp2x, blurData[5].curveParams.cp2y, blurData[5].curveParams.x, blurData[5].curveParams.y);

          if(tick / 200  > 0 && tick / 200 <= 1) {
           ctx.lineTo(blurData[1].lx-=0.25, blurData[1].ly+=0.25);
           ctx.bezierCurveTo(blurData[1].curveParams.cp1x-=0.25, blurData[1].curveParams.cp1y+=0.25, blurData[1].curveParams.cp2x-=0.25, blurData[1].curveParams.cp2y+=0.25, blurData[1].curveParams.x-=0.25, blurData[1].curveParams.y+=0.25);
    
           ctx.lineTo(blurData[2].lx-=0.25, blurData[2].ly+=0.25);
           ctx.bezierCurveTo(blurData[2].curveParams.cp1x-=0.25, blurData[2].curveParams.cp1y+=0.25, blurData[2].curveParams.cp2x-=0.25, blurData[2].curveParams.cp2y+=0.25, blurData[2].curveParams.x-=0.25, blurData[2].curveParams.y+=0.25);
    
         } else if (tick / 200  > 1 && tick / 200 <= 2){
    
           ctx.lineTo(blurData[1].lx+=0.25, blurData[1].ly-=0.25);
           ctx.bezierCurveTo(blurData[1].curveParams.cp1x+=0.25, blurData[1].curveParams.cp1y-=0.25, blurData[1].curveParams.cp2x+=0.25, blurData[1].curveParams.cp2y-=0.25, blurData[1].curveParams.x+=0.25, blurData[1].curveParams.y-=0.25);
    
           ctx.lineTo(blurData[3].lx+=0.25, blurData[3].ly+=0.25);
           ctx.bezierCurveTo(blurData[3].curveParams.cp1x+=0.25, blurData[3].curveParams.cp1y+=0.25, blurData[3].curveParams.cp2x+=0.25, blurData[3].curveParams.cp2y+=0.25, blurData[3].curveParams.x+=0.25, blurData[3].curveParams.y+=0.25);
    
          } else if (tick / 200  > 2 && tick / 200 <= 3){
    
           ctx.lineTo(blurData[2].lx+=0.25, blurData[2].ly-=0.25);
           ctx.bezierCurveTo(blurData[2].curveParams.cp1x+=0.25, blurData[2].curveParams.cp1y-=0.25, blurData[2].curveParams.cp2x+=0.25, blurData[2].curveParams.cp2y-=0.25, blurData[2].curveParams.x+=0.25, blurData[2].curveParams.y-=0.25);
    
           ctx.lineTo(blurData[4].lx+=0.25, blurData[4].ly-=0.25);
           ctx.bezierCurveTo(blurData[4].curveParams.cp1x+=0.25, blurData[4].curveParams.cp1y-=0.25, blurData[4].curveParams.cp2x+=0.25, blurData[4].curveParams.cp2y-=0.25, blurData[4].curveParams.x+=0.25, blurData[4].curveParams.y-=0.25);
    
    
          } else if (tick / 200  > 3 && tick / 200 <= 4){
    
           ctx.lineTo(blurData[5].lx+=0.25, blurData[5].ly-=0.25);
           ctx.bezierCurveTo(blurData[5].curveParams.cp1x+=0.25, blurData[5].curveParams.cp1y-=0.25, blurData[5].curveParams.cp2x+=0.25, blurData[5].curveParams.cp2y-=0.25, blurData[5].curveParams.x+=0.25, blurData[5].curveParams.y-=0.25);
    
           ctx.moveTo(blurData[0].mx+=0.25, blurData[0].my-=0.25);
           ctx.lineTo(blurData[0].lx+=0.25, blurData[0].ly-=0.25);
           ctx.bezierCurveTo(blurData[0].curveParams.cp1x+=0.25, blurData[0].curveParams.cp1y-=0.25, blurData[0].curveParams.cp2x+=0.25, blurData[0].curveParams.cp2y-=0.25, blurData[0].curveParams.x+=0.25, blurData[0].curveParams.y-=0.25);
    
           ctx.lineTo(blurData[3].lx-=0.25, blurData[3].ly-=0.25);
           ctx.bezierCurveTo(blurData[3].curveParams.cp1x-=0.25, blurData[3].curveParams.cp1y-=0.25, blurData[3].curveParams.cp2x-=0.25, blurData[3].curveParams.cp2y-=0.25, blurData[3].curveParams.x-=0.25, blurData[3].curveParams.y-=0.25);
    
          } else if (tick / 200  > 4 && tick / 200 <= 5){
    
           ctx.lineTo(blurData[4].lx-=0.25, blurData[4].ly+=0.25);
           ctx.bezierCurveTo(blurData[4].curveParams.cp1x-=0.25, blurData[4].curveParams.cp1y+=0.25, blurData[4].curveParams.cp2x-=0.25, blurData[4].curveParams.cp2y+=0.25, blurData[4].curveParams.x-=0.25, blurData[4].curveParams.y+=0.25);
    
           ctx.lineTo(blurData[5].lx-=0.25, blurData[5].ly+=0.25);
           ctx.bezierCurveTo(blurData[5].curveParams.cp1x-=0.25, blurData[5].curveParams.cp1y+=0.25, blurData[5].curveParams.cp2x-=0.25, blurData[5].curveParams.cp2y+=0.25, blurData[5].curveParams.x-=0.25, blurData[5].curveParams.y+=0.25);
    
           ctx.moveTo(blurData[0].mx-=0.25, blurData[0].my+=0.25);
           ctx.lineTo(blurData[0].lx-=0.25, blurData[0].ly+=0.25);
           ctx.bezierCurveTo(blurData[0].curveParams.cp1x-=0.25, blurData[0].curveParams.cp1y+=0.25, blurData[0].curveParams.cp2x-=0.25, blurData[0].curveParams.cp2y+=0.25, blurData[0].curveParams.x-=0.25, blurData[0].curveParams.y+=0.25);
          }  

          tick++;

          if(tick / 200 >= 5) {
              tick = 0.25;
          }

          ctx.closePath();
          ctx.shadowColor = "rgb(34, 208, 173)";
          ctx.shadowBlur = 20;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = deltaShadow + 500;
          ctx.fill();
          ctx.restore();


          window.requestAnimationFrame(Animate)
      }

      setTimeout(() => {
        Animate();
      }, 1500)
   }



   const LANGUAGE = document.querySelector('html').getAttribute('lang');

   const $tagsGroup = document.querySelectorAll('.js-setting-tags')
   const $apply = document.querySelector('.js-apply')
   const $errorMessage = document.querySelector('.js-error-message')
   const $privacyTags = document.querySelectorAll('.js-privacy-tag')

   let values = {
       app: '',
       os: '',
       level: ''
   };

   let apps = [];

   const initValues = () => {
       $tagsGroup.forEach(el => {
           const type = el.getAttribute('data-type');
           const $tags = el.querySelectorAll('.js-setting-tag');
           $tags.forEach($tag => {
               if ($tag.classList.contains('_active')) {
                   const id = $tag.getAttribute('data-id');
                   values[type] = id;
                   const $select = document.querySelector(`.js-settings-select[data-select=${type}]`)
                   const selectText = $select.querySelector('.select__text')
                   const popup = document.querySelector(`[data-popup=${type}]`)
                   const $mobileTag = popup.querySelector(`.js-mobile-setting[data-id="${id}"]`)
                   selectText.innerHTML = $tag.querySelector('.setting-tag').textContent
                   $select.setAttribute('data-option', $tag.querySelector('.setting-tag').textContent)
                   selectText.classList.add('_active')
                   selectText.classList.remove('_error')
                   $mobileTag.classList.add('_active')
               }
           })
       })
   }

   initValues();

   const prepareDataToRender = (data, type) => {
       const platform = data.platform;
       const services = data.services;
       apps = data.services;

       if (type !== 'app') {
           renderFox(data.foxUrl);
       }

       if (platform.length > 0) {
           prepareDesktopHTMLToRender(platform, 'os');
           prepareMobileHTMLToRender(platform, 'os');
       }

       if (services.length > 0) {
           prepareDesktopHTMLToRenderRows(services, 'app');
           prepareMobileHTMLToRender(services, 'app');
       }
   };
   let prevUrlFox = ''
   const renderFox = (url) => {
       const lottie = document.querySelector('.index-settings__picture lottie-player');
       const wrapper = document.querySelector('.index-settings__picture');
       if (url !== undefined && !!lottie && prevUrlFox !== url) {
           wrapper.style.display = "none";
           prevUrlFox = url;
           lottie.load(url);
           setTimeout(() => {
               wrapper.style.display = "block";
           }, 300);
       }
   }
   const prepareDesktopHTMLToRenderRows = (items, searchType) => {
       const parent = document.querySelector(`[data-type=${searchType}]`);
       const rows = parent.querySelectorAll(`.js-settings-row`);
       const fragment = document.createDocumentFragment();
       const itemTemplate = rows[0].children[0].cloneNode(true);
       parent.innerHTML = '';

       rows.forEach(row => {
           const rowType = row.getAttribute('data-row');
           row.innerHTML = '';
           items.forEach(item => {
               const itemType = item.type ?? 'other';
               if(itemType === rowType) {
                   itemTemplate.classList.remove('_active')
                   const block = itemTemplate.cloneNode(true);

                   block.querySelector('.setting-tag').textContent = item.name;
                   block.setAttribute('data-id', item.id);

                   if (block.classList.contains('_active')) {
                       block.classList.remove('_active')
                   }

                   console.log(values[searchType]);
                   if (item.id == values[searchType]) {
                       block.classList.add('_active');
                   }

                   fragment.appendChild(block);
               }
               insertHTML(row, fragment)
           })
           insertHTML(parent, row);
       });
       toggleSettingRows();
   }

   const toggleSettingRows = () => {
       let rows = document.querySelectorAll(`.js-settings-row`);
       console.log(rows);
       rows.forEach(row => {
           if(row.innerHTML === '' || (row.childNodes.length === 1 && row.childNodes[0].nodeName === '#text')) {
               row.style.display = 'none';
           } else {
               row.style.display = '';
           }
       });
   }

   (() => {
       toggleSettingRows();
   })();


   const prepareDesktopHTMLToRender = (items, searchType) => {
       const parent = document.querySelector(`[data-type=${searchType}]`);
       const children = parent.children;
       const fragment = document.createDocumentFragment();
       const template = children[0].cloneNode(true);
       template.classList.remove('_active')
       parent.innerHTML = '';

       items.forEach(item => {
           const block = template.cloneNode(true);

           block.querySelector('.setting-tag').textContent = item.name;
           block.setAttribute('data-id', item.id);

           if (block.classList.contains('_active')) {
               block.classList.remove('_active')
           }

           if (item.id == values[searchType]) {
               block.classList.add('_active');
           }

           fragment.appendChild(block);
       })

       insertHTML(parent, fragment);
   };

   const prepareMobileHTMLToRender = (items, searchType) => {
       const parent = document.querySelector(`.js-popup[data-popup=${searchType}]`);
       const children = parent.querySelectorAll('.mobile-settings__item');
       const fragment = document.createDocumentFragment();
       const template = children[0].cloneNode(true);
       template.classList.remove('_active')

       parent.querySelector('.mobile-settings').innerHTML = '';

       items.forEach(item => {
           const block = template.cloneNode(true);
           const child = block.querySelector('.js-mobile-setting');
           child.textContent = item.name;
           child.setAttribute('data-id', item.id);
           child.setAttribute('data-option', item.name);

           if (item.id == values[searchType]) {
               child.classList.add('_active');
           } else {
               child.classList.remove('_active');
           }


           fragment.appendChild(block);
       })

       insertHTML(parent.querySelector('.mobile-settings'), fragment);
   };

   const insertHTML = (parent, children) => {
       parent.appendChild(children);
   }

   // select setting tag

   window.addEventListener('click', (event) => {

       if (!!event.target && event.target.classList.contains('setting-tag')) {
           const $tag = event.target.closest('.js-setting-tag');
           const $tagsParent = $tag.closest('.js-setting-tags');
           const $tags = $tagsParent.querySelectorAll('.js-setting-tag');

           $tags.forEach($tag => {
               if ($tag.classList.contains('_active')) {
                   $tag.classList.remove('_active')
               }
           })

           $tag.classList.add('_active');

           if ($tagsParent.classList.contains('_error')) {
               $tagsParent.classList.remove('_error')
           }
           if (!!$errorMessage && $errorMessage.classList.contains('_active')) {
               $errorMessage.classList.remove('_active')
           }

           const type = $tagsParent.getAttribute('data-type');
           const id = $tag.getAttribute('data-id');
           values[type] = id;

           sendData(
               `/api/indexPage?lang=${LANGUAGE}&level=${values.level}&platform=${values.os}`,
               'get',
               '',
               function (response) {
                   response = JSON.parse(response);
                   if (response.status === "ok") {
                       prepareDataToRender(response.data, type);
                   }
               })
       }
   })

   const $noteNode = document.querySelector('.js-privacy-level-note');
   if (!!$noteNode) {
       $noteNode.classList.add('_animate')
   }

   $privacyTags.forEach($el => {
       $el.addEventListener('click', () => {
           const note = $el.getAttribute('data-note')
           const $noteNode = document.querySelector('.js-privacy-level-note')
           if (!!$noteNode) {
               $noteNode.classList.remove('_animate')
               $noteNode.querySelector('span').innerText = note
               setTimeout(() => {
                   $noteNode.classList.add('_animate')
               }, 300)
           }
       })
   })

   // apply button handler

   const checkTagGroup = (el) => {
       let valid = false
       const $tags = el.querySelectorAll('.js-setting-tag')

       $tags.forEach($tag => {
           if ($tag.classList.contains('_active')) {
               valid = true
           }
       })

       return valid

   }

   if (!!$apply) {
       $apply.addEventListener('click', (e) => {
           e.preventDefault()
           let valid = true
           $tagsGroup.forEach(el => {

               if (!checkTagGroup(el)) {
                   el.classList.add('_error')
                   valid = false

                   setTimeout(() => {
                       el.classList.remove('_error')
                   }, 2000)

                   if (!!$errorMessage) {
                       $errorMessage.classList.add('_active')
                   }
               }

           })

           if (valid) {
               const selectedApp = apps.filter(app => app.id == values.app)[0]

               if (!!selectedApp) {
                   window.location = selectedApp.link;
               }
           }

       })
   }


   // mobile

   const $settingSelect = document.querySelectorAll('.js-settings-select')

   $settingSelect.forEach($el => {
       $el.addEventListener('click', () => {
           const dataSelect = $el.getAttribute('data-select')
           togglePopup(dataSelect)
       })
   })

   const $mobileSetting = document.querySelectorAll('.js-mobile-setting')

   window.addEventListener('click', (event) => {
       if (event.target && event.target.classList.contains('js-mobile-setting')) {
           const $el = event.target;
           const selectId = $el.closest('.js-popup').getAttribute('data-popup')
           const $select = document.querySelector(`.js-settings-select[data-select=${selectId}]`)
           const selectText = $select.querySelector('.select__text')
           const $elements = $el.closest('.js-popup').querySelectorAll('.js-mobile-setting')

           $elements.forEach($element => $element.classList.remove('_active'))

           selectText.innerHTML = $el.innerHTML
           $select.setAttribute('data-option', $el.getAttribute('data-option'))
           selectText.classList.add('_active')
           $el.classList.add('_active');
           selectText.classList.remove('_error')

           values[selectId] = $el.getAttribute('data-id');
           if (!!$errorMessage) {
               $errorMessage.classList.remove('_active')
           }
           togglePopup(selectId)

           sendData(
               `/api/indexPage?lang=${LANGUAGE}&level=${values.level}&platform=${values.os}`,
               'get',
               '',
               function (response) {
                   response = JSON.parse(response);
                   if (response.status === "ok") {
                       prepareDataToRender(response.data, selectId);
                   }
               })
       }
   })

   const $applyMobile = document.querySelector('.js-apply-mobile')

   const validateSelect = () => {
       let valid = true
       const $select = document.querySelectorAll('.js-settings-select')

       $select.forEach($el => {
           // !el.getAttribute('data-option') === 'empty' ? valid = false : valid = true
           if ($el.getAttribute('data-option') === 'empty') {
               valid = false
               $el.querySelector('.select__text').classList.add('_error')
               if (!!$errorMessage) {
                   $errorMessage.classList.add('_active')
               }
           }
       })

       return valid
   }

   if (!!$applyMobile) {
       $applyMobile.addEventListener('click', (e) => {
           e.preventDefault()
           if (validateSelect()) {
               // action if all settings selected
               const selectedApp = apps.find(app => app.id == values.app)
               if (!!selectedApp) {
                   window.location = selectedApp.link;
               }

           } else {

           }
       })
   }


   // accordion controls

   function checkAllAccordions($el) {
      const parent = $el.closest('.article-block');
      const btn = parent.querySelector('.js-open-all')

      if (parent.querySelectorAll('.article-block__item').length === parent.querySelectorAll('.article-block__item._open').length) {
         btn.textContent = btn.getAttribute('data-close');
         btn.setAttribute('data-status', "true") ;
      } else {
         btn.textContent = btn.getAttribute('data-open');
         btn.setAttribute('data-status', "") ;
      }
   }

   const $accordions = document.querySelectorAll('.js-accordion-trigger')

   $accordions.forEach($el => {
      $el.addEventListener('click', () => {
         const $content = $el.nextElementSibling
         $el.parentNode.classList.toggle('_open')
         $content.style.height ? $content.style.height = null : $content.style.height = `${$content.scrollHeight}px`
         checkAllAccordions($el);
      })
   })

   if (location.hash && !!$accordions.length) {
     const $target =  document.getElementById(`${location.hash.slice(1)}`).querySelector('.js-accordion-trigger')
       if (!$target.parentNode.classList.contains('_open')) {
         setTimeout(() => {
           const $content = $target.nextElementSibling
           $target.parentNode.classList.add('_open')
           $content.style.height = `${$content.scrollHeight}px`
         }, 400)
     }
   }

   // open mobile settings

   const $changeSettings = document.querySelector('.js-change-settings');

   if (!!$changeSettings) {
      $changeSettings.addEventListener('click', () => {
         togglePopup($changeSettings.getAttribute('data-popup'))
      })
   }

   // stars (only visual effect)

   const $stars = document.querySelectorAll('.js-rate')

   $stars.forEach(($star, index) => {
      let i = index
      $star.addEventListener('click', function() {
         $stars.forEach($star => {
            $star.classList.remove('_active')
         })
         $stars.forEach(($star, index) => {
            if (index <= i) $star.classList.add('_active')
         })
      })
   })

   // image popups

   const $images = document.querySelectorAll('.js-article img')
   const $imagePopup = document.querySelector(`.js-popup[data-popup=image]`)

   if (!!$imagePopup) {
      const $closeImagePopup = $imagePopup.querySelectorAll('.js-close-popup')

      $closeImagePopup.forEach($el => {
         $el.addEventListener('click', blurBg)
      })
   }

   if (!!$images) {
      $images.forEach($img => {
         $img.addEventListener('click', function() {
            const $innerImg = $imagePopup.querySelector('img'),
                  src = this.getAttribute('src')
         
            $innerImg.src = src
            $innerImg.removeAttribute('class')
         
            if (this.offsetWidth < this.offsetHeight) {
               $innerImg.classList.add('_narrow')
            } else $innerImg.classList.add('_wide')
         
            togglePopup('image', true)
         })
      })
   }

   const $openAllBtn = document.querySelectorAll('.js-open-all');

   if (!!$openAllBtn) {
      $openAllBtn.forEach(function ($btn) {
         $btn.addEventListener('click', function () {
            const $parent = $btn.closest('.article-block');
            const $accordionItems = $parent.querySelectorAll('.article-block__item');
            const opened = !!$parent.querySelector('.js-open-all').getAttribute('data-status');

            $accordionItems.forEach(function ($accordionItem) {
               const $content = $accordionItem.querySelector('.accordion__content');
               if (opened) {
                  $accordionItem.classList.remove('_open')
                  $content.style.height = null
               } else {
                  $accordionItem.classList.add('_open')
                  $content.style.height = `${$content.scrollHeight}px`
               }

            })

            checkAllAccordions($btn)
         })
      })
   }

   const $articleHeading = document.querySelectorAll('.article-block__heading');

   if (!!$articleHeading) {
      $articleHeading.forEach(function ($btn) {
         $btn.addEventListener('click', function () {
            const $parent = $btn.closest('.article-block');
            const $accordionItems = $parent.querySelectorAll('.article-block__item');
            const opened = !!$parent.querySelector('.js-open-all').getAttribute('data-status');

            $accordionItems.forEach(function ($accordionItem) {
               const $content = $accordionItem.querySelector('.accordion__content');
               if (opened) {
                  $accordionItem.classList.remove('_open')
                  $content.style.height = null
               } else {
                  $accordionItem.classList.add('_open')
                  $content.style.height = `${$content.scrollHeight}px`
               }
            })

            checkAllAccordions($btn)
         })
      })
   }

   const activeFoxLink = document.querySelector('.js-active-fox');

   if (!!activeFoxLink && window.document.documentMode) {
      const image = activeFoxLink.querySelector('img')
      image.src = image.src.slice(0,-4) + '-gray.png';
   }

   // form validation

   class Validate {
     constructor(selector, onValid) {
       this.form = null,
         this.selector = selector,
         this.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
       this.isValid = true,
         this.onValid = onValid
     }

     _handleSubmit() {
       const $inputs = this.form.querySelectorAll('input, textarea')
       this.isValid = true
       $inputs.forEach($input => {
         if (!this._validate($input)) {
           $input.classList.add('_error')
           this.isValid = false
         }
       })

       if (this.isValid) {
         $inputs.forEach($input => {
           if ($input.classList.contains('_error')) {
             $input.classList.remove('_error')
           }
         })

         this.onValid()

         $inputs.forEach($input => {
           $input.removeEventListener('input', () => {
             this._handleInput($input)
           })
         })
         this.isValid = true
         this.form.reset()

       } else {
         $inputs.forEach($input => {
           $input.addEventListener('input', () => {
             this._handleInput($input)
           })
         })
       }
     }

     _handleInput($input) {
       if (!this._validate($input) && !this.isValid) {
         $input.classList.add('_error')
       } else {
         $input.classList.remove('_error')
       }
     }

     _validate($input) {
       const val = $input.value.trim()

       if ($input.getAttribute('data-validate') == 'email') {
         return this.emailPattern.test(val)
       } else {
         return val !== ''
       }

     }

     init() {

       this.form = document.querySelector(this.selector)

       if (!!this.form) {
         this.form.addEventListener('submit', (e) => {
           e.preventDefault()
           this._handleSubmit()
         })
       }

     }
   }

   const FeedBackForm = new Validate('.js-feedback-form', () => {
     let formData = new FormData(document.forms.feedback);

     sendData('/save-review/', 'POST', formData, function (response) {

         response = JSON.parse(response);

         if (!!response.status) {
           togglePopup('feedback')

           const $error = document.querySelector('.feedback-form__error');

           if (!!$error) {
             $error.classList.remove('_active');
           }
         } else {
           const $error = document.querySelector('.feedback-form__error');

           if (!!$error) {
             $error.classList.add('_active');
           }
         }
       },
       function () {
         const $error = document.querySelector('.feedback-form__error');

         if (!!$error) {
           $error.classList.add('_active');
         }
       }
     )

   });

   FeedBackForm.init()


   // copy code

   const $copyBtn = document.querySelector('.js-copy-code')

   if (!!$copyBtn) {

     $copyBtn.addEventListener('click', () => {
       let $code = document.querySelector('.js-code-block code')

       let text = $code.innerText

       if (!!navigator.clipboard) {
         navigator.clipboard.writeText(text)
       } else if (!!window.clipboardData) {
         window.clipboardData.setData("Text", text);
       }

       const $copied = document.querySelector('.js-copied');
       $copied.classList.add('_active');

       setTimeout(() => {
         $copied.classList.remove('_active');
       }, 1000)
     })
   }

   const $checkbox = document.querySelector('.js-checkbox-responsive')
   const $responsiveField = document.querySelector('.js-switch-responsive')
   if (!!$checkbox) {
     $checkbox.addEventListener('change', () => {
       $responsiveField.textContent = $checkbox.checked;
       const label = $checkbox.closest('.checkbox__label');
       if(label) {
         if($checkbox.checked) {
           label.querySelector('.checkbox__layout').classList.add('checked');
         } else {
           label.querySelector('.checkbox__layout').classList.remove('checked');
         }
       }
     })
   }


   // ie form inputs effects

   if (detectIE()) {
     const $formInputs = document.querySelectorAll('.js-feedback-form input, .js-feedback-form textarea')

     if (!!$formInputs) {
       $formInputs.forEach($input => {
         $input.addEventListener('focus', function () {
           this.classList.add('_is-up')
         })
         $input.addEventListener('blur', function () {
           if (!this.value.trim()) this.classList.remove('_is-up')
         })
       })
     }
   }

   const tabs = document.querySelector('.tabs__inner');

   if (!!tabs) {
     new SimpleBar(tabs);
   }

   function getCookie(name) {
       var matches = document.cookie.match(new RegExp(
           "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
       ));
       return matches ? decodeURIComponent(matches[1]) : undefined;
   }

   function setCookie(name, value, options = {}) {

       options.path = '/';

       var updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

       for (var optionKey in options) {
           updatedCookie += "; " + optionKey;
           var optionValue = options[optionKey];
           if (optionValue !== true) {
               updatedCookie += "=" + optionValue;
           }
       }

       document.cookie = updatedCookie;
   }


   var notificationText = {
       ru: '<div class="notification-bar__description"><p class="notification-bar__text">Мы используем файлы cookie, чтобы улучшить работу сайта. Дальнейшее пребывание на сайте означает согласие с их применением. <a class="notification-bar__text_link" href="https://www.kaspersky.ru/web-privacy-policy" >Подробнее</a></p></div><span  class="notification-bar__button">Принять и закрыть</span>',
       en: '<div class="notification-bar__description"><p class="notification-bar__text">We use cookies to make your experience of our websites better. By using and further navigating this website you accept this. Detailed information about the use of cookies on this website is available by clicking on <a class="notification-bar__text_link" href="https://www.kaspersky.com/web-privacy-policy" >more information</a>.</p></div><span  class="notification-bar__button">Accept and Close</span>',
       es: '<div class="notification-bar__description"><p class="notification-bar__text">Utilizamos cookies para mejorar tu experiencia en nuestros sitios web. Al utilizar y seguir navegando por este sitio web, aceptas las cookies. Haz clic en <a class="notification-bar__text_link" href="https://www.kaspersky.com/web-privacy-policy" >"más información"</a> para obtener información detallada sobre el uso de cookies en este sitio web.</p></div><span  class="notification-bar__button">Aceptar y cerrar</span>',
       de: '<div class="notification-bar__description"><p class="notification-bar__text">Wir verwenden Cookies, damit Ihr Erlebnis auf unseren Webseiten noch besser wird. Durch die Nutzung und Navigation dieser Webseite akzeptieren Sie dies. Ausführlichere Informationen zur Nutzung von Cookies auf dieser Webseite finden Sie, wenn Sie auf <a class="notification-bar__text_link" href="https://www.kaspersky.de/web-privacy-policy" >„Weitere Informationen“</a> klicken.</p></div><span  class="notification-bar__button">Akzeptieren</span>',
       fr: '<div class="notification-bar__description"><p class="notification-bar__text">Nous utilisons des cookies pour améliorer votre expérience sur nos sites Web. En utilisant ce site Web et en poursuivant votre navigation, vous acceptez ces conditions. Des informations détaillées sur l\'utilisation des cookies sur ce site Web sont disponibles en cliquant sur .<a class="notification-bar__text_link" href="https://www.kaspersky.com/web-privacy-policy" >plus d\'informations</a>.</p></div><span  class="notification-bar__button">Accepter et fermer</span>'
   }

   if (notificationText[document.documentElement.lang] !== undefined) {
       document.querySelector('.js-notification-bar__inner').innerHTML = notificationText[document.documentElement.lang]
   } else {
       document.querySelector('.js-notification-bar__inner').innerHTML = notificationText.en
   }


   if (!getCookie("notification")) {
       document.querySelector('.notification-bar').classList.add('is-active');
   }

   document.querySelector('.notification-bar__button').addEventListener('click', function (event) {
       document.querySelector('.notification-bar').style.display = 'none';
       setCookie('notification', 'true', {'max-age': 60 * 60 * 24 * 30});
   });
});

if(!/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
   var foxes = document.querySelectorAll('.js-fox');
   if(foxes.length) {

      for(var i = 0; i < foxes.length; i++) {
         var file = foxes[i].dataset.url;
         foxes[i].innerHTML = '<lottie-player src="' + file + '" background="transparent" speed="1" loop autoplay></lottie-player>';
      }

      /*var lottieScript = document.createElement('script');
      lottieScript.src = "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
      lottieScript.defer = true;
      document.body.append(lottieScript);*/
   }
}